/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import AniLink from "gatsby-plugin-transition-link/AniLink";


// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// React icons
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
<CustomDropdown
          noLiPadding
          buttonText="About Us"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
        
          dropdownList={[
            <Link to="/about" className={classes.dropdownLink}>
            About us
          </Link>,
            <Link to="/team" className={classes.dropdownLink}>
              Meet the team
            </Link>,
            <Link to="/what-we-do" className={classes.dropdownLink}>
              What we do
            </Link>,
            <Link to="/esg" className={classes.dropdownLink}>
              ESG
            </Link>,
            <Link to="/partners" className={classes.dropdownLink}>
                Partners
            </Link>,
          ]}
        />   
      </ListItem>
      <ListItem className={classes.listItem}>
      <CustomDropdown
          noLiPadding
          buttonText="OUR DEVELOPMENTS"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
        
          dropdownList={[
            <Link to="/developments" className={classes.dropdownLink}>
              Colliery Business Park
            </Link>,
            <Link to="/developments" className={classes.dropdownLink}>
              In Planning
            </Link>,
            <Link to="/case-studies" className={classes.dropdownLink}>
              Case Studies
            </Link>,
          ]}
        />  
      </ListItem>
      <ListItem className={classes.listItem}>
      <AniLink cover direction="up" duration={2} to="/news-research" bg="linear-gradient(60deg, #164777 40%, #164777 80%)">
          <Button color="transparent" className={classes.navLink}>
  NEWS &amp; RESEARCH
  </Button>
</AniLink>
      </ListItem>
      <ListItem className={classes.listItem}>
      <AniLink cover direction="up" duration={2} to="/contact" bg="linear-gradient(60deg, #164777 40%, #164777 80%)">
          <Button color="transparent" className={classes.navLink}>
  CONTACT US
  </Button>
</AniLink>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <FaTwitter/>
          </Button>
        </Tooltip>
      </ListItem> */}
      <listItem></listItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
