/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import { Link } from "gatsby";
import classNames from "classnames";
import { List, ListItem, withStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import vcdlogo from "assets/img/vcdlogo.png";
import calogo from "assets/img/calogo.png";
import wclogo from "assets/img/wclogo.png";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
      <GridContainer
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={0}
        >
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
              <Link to="/">
                <Button className={classes.footerbuttons} color="transparent">
                  Home
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
              <Link to="/about">
                <Button className={classes.footerbuttons} color="transparent">
                  About Us
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
              <Link to="/developments">
                <Button className={classes.footerbuttons} color="transparent">
                  Developments
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
            <Link to="/developments">
                <Button className={classes.footerbuttons} color="transparent">
                Colliery Business Park
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
            <Link to="/team">
                <Button className={classes.footerbuttons} color="transparent">
                  Team
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
            <Link to="/case-studies">
                <Button className={classes.footerbuttons} color="transparent">
                Case Studies
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
            <Link to="/contact">
                <Button className={classes.footerbuttons} color="transparent">
                  Contact Us
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
            <Link to="/partners">
                <Button className={classes.footerbuttons} color="transparent">
                  Partners
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
            <ListItem>
            <Link to="/esg">
                <Button className={classes.footerbuttons} color="transparent">
                  ESG
                </Button>
              </Link>
            </ListItem>
          </GridItem>
          <GridItem md={4} sm={6} xs={6}>
          <ListItem>
            <div className={classes.crvcd}>
                &copy; {1900 + new Date().getYear()} ,{" "}
                  {" "}
                  Virtuous Circle Developments
                </div>
            </ListItem>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
