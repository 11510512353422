import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttonspecs from "../../assets/jss/material-kit-react/buttondownload.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";


function SpecButton() {
    return (
      <div>
                <AniLink className="downloadbutton" paintDrip to="/specifications"  hex="#192637">
                SPECIFICATIONS
</AniLink>
        {/* <Link className="downloadbutton" to="/specifications">SPECIFICATIONS</Link> */}
      </div>
    )
  }

  export default withStyles(buttonspecs)(SpecButton);